.headerContainer {
  position: sticky;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 5%;
  padding-right: 5%;
  width: 100%;
  box-sizing: border-box;
  z-index: 1000;
  height: 75px;
  top: 0;
}

.headerContainer h1 {
  letter-spacing: 1.4vw; /* Adjust the value as needed */
}

.nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 60%;
  font-family: 'Saira Extra Condensed', sans-serif;
  font-weight: 500;
  font-size: 30px;
  letter-spacing: 2px;
  color: whitesmoke;
}


.title a:hover {
  text-decoration: none;
}

h3{
  padding:8px;
  border-radius: 25px;
}
h3:hover{
  background-color: var(--menu-hover);
}

.designSection,
.constructionSection,
.aboutSection,
.studiesSection {
  position: relative;
  display: inline-block;
}

.scrollLinks {
  display: none;
  position: absolute;
  top: 80%; 
  left: 0%; 
  background-color: var(--menu-hover);
  z-index: 1;
  min-width: 180px;
  border-radius: 25px;
}

.scrollLinks .scrollTrigger {
  display: block;
  padding: 12px 16px;
  text-decoration: none;
  color: white;
}

.scrollLinks .scrollTrigger:hover {
  background-color: #9d9d9d;
  border-radius: 25px;

}

.studiesSection:hover .scrollLinks {
  display: block;
}

.designSection:hover .scrollLinks {
  display: block;
}

.constructionSection:hover .scrollLinks {
  display: block;
}

.aboutSection:hover .scrollLinks {
  display: block;
}

.scrollLinks .active {
  background-color: var(--menu-hover);
  text-decoration: underline;
}

.active h3 {
  background-color: var(--menu-hover);
}

.title img {
  width: 100px;
  height: 100px;
}

/* Sidebar */
/* The side navigation menu */
.sideNav {
  display: none;
  height: 100%; /* 100% Full-height */
  width: 0; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  z-index: 1; /* Stay on top */
  top: 0; /* Stay at the top */
  left: 0;
  background-color: rgba(2, 2, 2, 1);
  overflow-x: hidden; /* Disable horizontal scroll */
  transition: 0.5s; /* 0.5 second transition effect to slide in the sideNav */
}

/* The navigation menu links */
.sideNav a {
  text-decoration: none;
  transition: 0.3s;
}
.sideNav h3 {
  display: block;
  padding: 8px 8px 8px 32px;
  font-weight: 100;
  font-size: clamp(2rem, 2.5vw, 2rem);
  letter-spacing: 1vw; /* Adjust the value as needed */
  color: white;
}

/* When you mouse over the navigation links, change their color */
.sideNav a:hover {
  color: #f1f1f1;
}


.closeBtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

.hamburgerIcon {
  color: #000;
  display: none;
}

@media screen and (max-width: 1024px) {
  .nav {
    display: none;
  }

  .hamburgerIcon {
    display: block;
  }

  .sideNav {
    display: flex;
    flex-direction: column;
  }
}
