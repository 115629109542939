.contact-form{
    display: flex;
    flex-direction: row;
    padding:48px;
    width: 75%;
    margin: auto;
    box-sizing: border-box;
}

@media screen and (max-width: 900px) {
    .contact-form{
        flex-direction: column;
        padding:16px;
        width:100%;
    }
}
    
.info-box{
    padding:64px;
    flex:1;
    color: white;
    background-color: var(--primary-color);
}



.input-box{
    padding:64px;
    flex:2;
    background-color: #F8F9FA; 
}

.input-box h1{
    color:var(--primary-color)
}

@media screen and (max-width: 900px) {
    .info-box{
        padding:32px;
        flex-basis: 100%;
    }
    .input-box{
        padding:32px;
        flex-basis: 100%;
    }
}
    
ul{
    margin:0;
    padding:0;
    margin-top:2rem;
}
li{
    list-style-type: none;
    font-weight: bold;
    padding:1rem;
    padding-bottom:0.5rem;
}



.form-input-content{
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top:50px;
}

.form-input-fields{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.form-input-fields input{
    background-color: #F8F9FA; 
    width: 50%;
    padding: 0.5rem;
    margin-bottom:1rem;
    border: none;
    border-bottom: 1px solid var(--primary-color); 
    font: inherit;
}

.form-input-fields textarea{
    font: inherit;
    background-color: #F8F9FA; 
    width: 100%;
    min-height:100px;
    padding: 0.5rem;
    margin-bottom:1rem;
    border: none;
    border-bottom: 1px solid var(--primary-color);
}

.form-input-content{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.captcha-button{
    display: flex;
    flex-direction: column;
    align-items: center;
}


.contact-form input:focus,
.contact-form textarea:focus {
  outline: none;
  border: 0px solid #fff;
  box-shadow: 0 0 5px var(--primary-color);
}

.captcha-button{
    display: flex;
    flex-direction: column;
}
.captcha-button h3{
    text-align: center;
}
.submit-button{
    background-color: var(--primary-color);
    color: white;
    border: none;
    padding: 0.5rem;
    width: 50%;
    min-width:160px;
    margin-top: 1rem;
    font: inherit;
    cursor: pointer;
}