.background{
  min-height: 100vh;
  height: auto;
  position: relative;
}

.background::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url('../Assets/25846432.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left center;  
  background-color: var(--background-shade);
  background-blend-mode: multiply;
  background-attachment: fixed;
}

.homeContainer {
  height: 100vh;
  position: relative;
  overflow: auto;

}

.slideshowContainer {
  position: relative;
  width: 100%;
  height: 35%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.heroTitle {
  font-family: 'Saira Extra Condensed', sans-serif;
  font-weight: 100;
  font-size: clamp(1.2rem, 2vw, 1.5rem);
  letter-spacing: 0vw;
  color: white;
  padding: 0;
  margin: 0;
}

.promoText {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: 'Sofia Sans', sans-serif;
  font-weight: 400;
  width: 40%;
  min-height: 5%;
  max-height: fit-content;
  font-size: clamp(1.4rem, 2.5vw, 1.8rem);
  line-height: 35px;
  color: white;
  text-align: left;
  padding: 3rem;
  margin: 1rem;
  align-items: left;
  border-radius: 20px;
  letter-spacing: 1px;
}

@media (max-width: 1024px) {
  .promoText {
    width: 90%;
    margin: 0px;
    margin-top: 1rem;
    padding: 1rem;
    margin: 1rem;
  }
}

.bottomTextContainer {
  max-width: 80%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
}

@media (max-width: 768px) {
  .bottomTextContainer {
    flex-direction: column;
    align-items: center;
    max-width: 95%;
  }
}

.hrLine {
  border: none;
  border-top: 1px solid white;
  color: white;
  background-color: white;
  height: 1px;
  width: 50%;
}
