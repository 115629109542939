
.slideshow-image-container {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.slideshow-image {
  /* styling applied directly to the component*/
  object-fit: cover;
}

.slideshow-image-container.active {
  opacity: 1;
}
