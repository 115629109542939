@import url('https://fonts.googleapis.com/css2?family=Saira+Extra+Condensed:wght@200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sofia+Sans:wght@1;100;200;300;400;500;600;700;800;900;1000&display=swap');

/* Media Queries */
/*
@custom-media --phone (max-width: 768px);
@custom-media --table (min-width: 768px) and (max-width: 1024px);
@custom-media --macbook (min-width: 1024px) and (max-width: 1440px);
@custom-media --desktop (min-width: 1440px);
*/

:root {
  --primary-color: #8B959A;
  --secondary-color: #4c6069;
  --accent-color: #00f;
  --background-shade:rgba(0, 0, 0, 0.35);
  --menu-hover: #8b959aba;
}

body {
  overflow-y: scroll;
  height: 100%;
}

a {
  text-decoration: none;
  color: whitesmoke;
  padding: 10px;
}

p {
  font-family: 'Sofia Sans', sans-serif;
  font-weight: 400;
  font-size: clamp(1.3rem, 1.3vw, 2.3rem);
  letter-spacing: 3px;
  line-height: 40px;
  color: whitesmoke;
  text-align: left;
}

h1{
  font-family: 'Saira Extra Condensed', sans-serif;
  font-weight: 400;
  font-size: clamp(2rem, 3vw, 3rem);
  color: white;
  padding: 0;
  margin: 0;
  text-transform: uppercase;
  text-shadow: 0 0 1px black;

}

h2{
  text-shadow: 0 0 1px black;

}

h3{
  font-family: 'Sofia Sans', sans-serif;
  font-weight: 400;
  font-size: 2.5vw; 
  font-size: clamp(1.3rem, 2.5vw, 2.5rem);
  color: white;
  padding: 0;
  margin: 0;
  text-transform: uppercase;
  text-shadow: 0 0 1px black;

}

h4{
  font-family: 'Saira Extra Condensed', sans-serif;
  font-weight: 400;
  font-size: clamp(1.3rem, 2vw, 2rem);
  letter-spacing: 1px; 
  color: white;
  padding: 0;
  margin: 0;
  text-transform: uppercase;
  text-shadow: 0 0 1px black;

}


@media (max-width: 768px) {
  .section-container {
    flex-direction: column;
  }
}
