/* YourComponent.module.css */

.footerContainer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: fit-content;
  z-index: 1;
}

.footerText {
  font-family: 'Saira Extra Condensed', sans-serif;
  font-weight: 400;
  font-size: clamp(1rem, 2vw, 1.3rem);
  letter-spacing: 2px;
  color: white;
}

@media (max-width: 768px) {
  .footerText {
    letter-spacing: 0px;
  }
}

p.footerText {
  margin: 0px;
  margin-left: clamp(0.1rem, 2vw, 2rem);
}
