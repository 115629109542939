.parallaxContainer {
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: var(--background-shade);
  background-blend-mode: multiply;
}

.sectionContainer {
  position: relative;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: left;
}

.sectionContainerRight {
  justify-content: right;
}

.sectionContainer::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: inherit;
  z-index: -1;
}

@media (max-width: 768px) {
  .sectionContainer {
    flex-direction: column;
  }
  .sectionContainerRight {
    flex-direction: column-reverse;
  }

}

.sectionContent {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: flex-end;
  padding: 2rem;
  max-width: 40%;
  margin: 4%;
}

@media (max-width: 768px) {
  .sectionContent {
    max-width: 100%;
  }
}

.sectionText {
  font-family: 'Saira Extra Condensed', sans-serif;
  font-size: clamp(1.4rem, 2.5vw, 2rem);
  color: white;
  text-align: left;
  font-weight: 400;
  letter-spacing: 1px;
}

.sectionTitle {
  font-family: 'Saira Extra Condensed', sans-serif;
  font-size: clamp(1.5rem, 2.5vw, 2.5rem);
  color: var(--secondary-color);
  text-align: center;
  margin: 0;
  padding: 0;
  color: white;
}

.backgroundShading {
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
}

.slideshowContainer {
  position: relative;
  width: 100%;
  height: 35%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 800px;
  margin:32px;
}

.canvasContainer{
  background-color: #8b959a94;
  width: 500px;
  height: 500px;
}

@media (max-width: 768px) {
  .canvasContainer {
    width:95%
  }
}